<template>
  <v-container class="px-3 py-0" fluid>
    <v-row>
      <v-col cols=12 class="mt-sm-6">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TradeSlip",
}
</script>
